<style lang="scss" scoped>
.select-input {
  display: inline-block;
  width: 100%;
  //   select {
  //       background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+');
  //       background-repeat: no-repeat;
  //       background-size: 24px 24px;
  //       background-position: right center;
  //       &.empty {
  //           background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjY2NjY2NjIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+');
  //       }
  //   }

  label {
    padding-left: 0px;
  }

  .label--md {
    width: 100px;
  }

  .label--partner-form {
    width: 210px;
  }

  .required:after {
    color: #ff0000;
    content: " *";
    display: inline;
  }
}
.select-css {
  display: block;
  font-size: 14px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;
  margin: 0;
  //   border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcuNDEgNy44NEwxMiAxMi40Mmw0LjU5LTQuNThMMTggOS4yNWwtNiA2LTYtNnoiLz4gICAgPHBhdGggZD0iTTAtLjc1aDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+");

  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

body {
  padding: 3rem;
}
</style>
<template>
  <div class="select-input">
    <label :for="id" v-if="label" :class="labelClass">{{label}}</label>
    <select
      :id="id"
      type="text"
      class="select-css"
      :style="selectStyle"
      :value="value"
      @input="onInput"
      @focus="hasValue=''"
      @blur="hasValue = value ? '' : ' empty'"
    >
      <option v-if="!excludeBlank" value></option>
      <option v-for="option in options" :value="option.value">{{option.text}}</option>
    </select>
  </div>
</template>
<script>
export default {
    name: "SelectInput",
    data: function() {
        return {
            id: `select-input-${Math.random()
                .toString(36)
                .substring(7)}`,
            hasValue: this.value ? "" : " empty"
        };
    },
    watch: {
        value(value) {
            if (value === "") {
                this.hasValue = " empty";
            } else {
                this.hasValue = "";
            }
        }
    },
    props: ["value", "options", "label", "labelSize", "required", "excludeBlank", "maxWidth"],
    methods: {
        onInput(e) {
            this.$emit("input", e.target.value);
        }
    },
    computed: {
        labelClass() {
            let classes = {};
            const labelClass = this.labelSize
                ? `label--${this.labelSize}`
                : "label--md";
            const required = this.required ? this.required : false;

            classes[this.hasValue] = true;
            classes[labelClass] = true;
            classes["required"] = required;

            return classes;
        },
        selectStyle() {
            if (this.maxWidth == undefined) return {};
            return {
                maxWidth: this.maxWidth
            };
        }
    }
};
</script>
