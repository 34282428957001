var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-input" }, [
    _vm.label
      ? _c("label", { class: _vm.labelClass, attrs: { for: _vm.id } }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _c(
      "select",
      {
        staticClass: "select-css",
        style: _vm.selectStyle,
        attrs: { id: _vm.id, type: "text" },
        domProps: { value: _vm.value },
        on: {
          input: _vm.onInput,
          focus: function ($event) {
            _vm.hasValue = ""
          },
          blur: function ($event) {
            _vm.hasValue = _vm.value ? "" : " empty"
          },
        },
      },
      [
        !_vm.excludeBlank ? _c("option", { attrs: { value: "" } }) : _vm._e(),
        _vm._l(_vm.options, function (option) {
          return _c("option", { domProps: { value: option.value } }, [
            _vm._v(_vm._s(option.text)),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }